<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
  >
    <v-container>
      <div class="mt-0 mb-5">
        <h2>{{mode == 'new' ? 'メール送信グループ登録' : 'メール送信グループ編集'}}</h2>
      </div>

      <v-row>
        <v-col cols="12">
          <v-text-field
            label="委員会"
            v-model="mailSendGroupData.group_name1"
            maxlength="100"
            counter="100"
            dense
            :readonly="mode=='delete' ? true : false"
            :rules="[required_ns]"
            :loading="loading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="部会"
            v-model="mailSendGroupData.group_name2"
            maxlength="100"
            counter="100"
            dense
            :readonly="mode=='delete' ? true : false"
            :rules="[]"
            :loading="loading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="協会役職"
            v-model="mailSendGroupData.association_post"
            maxlength="100"
            counter="100"
            dense
            :readonly="mode=='delete' ? true : false"
            :loading="loading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="氏名"
            v-model="mailSendGroupData.name"
            maxlength="100"
            counter="100"
            dense
            :readonly="mode=='delete' ? true : false"
            :loading="loading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="メールアドレス"
            v-model="mailSendGroupData.email"
            maxlength="100"
            counter="100"
            dense
            :readonly="mode=='delete' ? true : false"
            :loading="loading"
            :rules="[required, validEmail]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="会社名"
            v-model="mailSendGroupData.company_name"
            maxlength="100"
            counter="100"
            dense
            :readonly="mode=='delete' ? true : false"
            :loading="loading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="役職"
            v-model="mailSendGroupData.post"
            maxlength="100"
            counter="100"
            dense
            :readonly="mode=='delete' ? true : false"
            :loading="loading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-text-field
            label="宛先"
            v-model="mailSendGroupData.to"
            maxlength="100"
            counter="100"
            dense
            :readonly="mode=='delete' ? true : false"
            :loading="loading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-0">
          <div class="mt-1">
            {{to_address}}
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="col-6 text-center">
          <v-btn class="btn_font primary" width="120" v-on:click="registData()" :disabled="loading" v-if="mode != 'delete'">登録</v-btn>
          <v-btn class="btn_font secondary" width="120" v-on:click="deleteData()" :disabled="loading" v-else>削除</v-btn>
        </v-col>
        <v-col class="col-6 text-center">
          <v-btn class="btn_font primary" width="120" v-on:click="close()" :disabled="loading" >キャンセル</v-btn>
        </v-col>
      </v-row>

    </v-container>
  </v-form>
 
</template>

<script>
import MailSendGroup from '../model/mailSendGroup'

import Message from '../Lib/Message'
import Debug from '../Lib/Debug'

export default {
  props: [
    'mode',
    'sid',
  ],

  components: {
  },

  data: () => ({
    valid : true,
    loading : false,

    mailSendGroupData: {
      sid: null,
      group_name1: null, 
      group_name2: null, 
      association_post: null, 
      name: null, 
      email: null, 
      company_name: null, 
      post: null, 
      to: "", 
    },

  }),

  methods: {
    // DBから 'sid' のデータを取得
    async getData(id) {
      Debug.log('function[getData]');
      Debug.log('Id:' + id);
      if (id == '') {
        // 新規の場合
        return;
      }

      this.loading = true;
      try {
        const res = await MailSendGroup.getData(id);
        if (res.data) {
          this.mailSendGroupData = res.data;
        }

        Debug.log(this.mailSendGroupData);

      } catch (error) {
        Message.err(error, '情報を取得できませんでした');
      }
      this.loading = false;

    },

    async registData() {
      Debug.log('function[registData]');

      // Vuetify Validation
      if (!this.$refs.form.validate()) {
        //Debug.log('Validation : false');
        return;
      }


      if (!confirm("更新します。よろしいですか？")) return;
      this.loading = true;
      try {
        const res = await MailSendGroup.setData(this.mailSendGroupData, this.mode);
        // Debug.log(res);

        // サーバーサイドのバリデーション 
        const validationMsg = res.data;
        if (validationMsg) {
          let message = "";
          if (Array.isArray(validationMsg)) {
            validationMsg.forEach(m => message += m + '\n')
          } else {
            message = validationMsg;
          }
          alert(message);

          this.loading = false;
          return;
        } else {
          // 
          alert("メール送信グループを更新しました");
          this.close();
        }

      } catch (error) {
        Message.err(error, "メール送信グループを更新できませんでした");
      }

      this.loading = false;
    },

    async deleteData() {
      Debug.log('function[deleteData]');

      if (!confirm("削除します。よろしいですか？")) return;
      this.loading = true;
      try {
        const res = await MailSendGroup.delData(this.sid);
        Debug.log(res);

        alert("メール送信グループを削除しました");
        this.close();
      } catch (error) {
        Message.err(error, "メール送信グループを削除できませんでした");
      }

      this.loading = false;
    },

    close() {
      this.$emit("closeEvt");
    },

  },

  created: function() {
    // Edit情報を取得
    Debug.log('mode:' + this.mode);
    Debug.log('Id:' + this.sid);

    this.getData(this.sid);
  },
  
  mounted: function() {

  },

  computed: {
    to_address() {
      if (!this.mailSendGroupData.email) {
        return "";
      }
      return this.mailSendGroupData.to + '<' + this.mailSendGroupData.email + '>';
    },
  },

}

</script>


<style scoped>
.container {
  position: relative;
  width: 95%    !important;
  left: 0;
  margin: 10px;
}

.btn_font {
  color: white;
}


.v-text-field {
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.v-input {
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: 4px;
  margin-bottom: 4px;
}

</style>
