<template>
  <v-container>
    <v-row >
      <v-col cols="2">
        <h2>メール送信グループ一覧</h2>
      </v-col>
      

      <v-col cols="2">
        <v-text-field label="検索" 
              dense v-model="searchString" 
              :disabled="loading"
              :loading="loading"
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-btn class="btn-font primary" v-on:click="show('', 'new')" :disabled="loading" >
          <v-icon left>mdi-plus-circle</v-icon>
          新規登録
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchString"
      ref="ref"
      :loading="loading"
      :dense=true
      :items-per-page="10"
      :mobile-breakpoint="0"
      :disable-pagination='true'
      :hide-default-footer='true'
      :fixed-header='true'
      height="calc(100vh - 220px)"
      multi-sort
      locale="ja-jp"
      loading-text="読込中"
      no-data-text="データがありません。"
      class="mt-5"
    >

      <template v-slot:item="{ item }">
        <tr :key="item.sid" @dblclick="show(item.sid, 'edit')">
          <td>
            {{item.group_name1}}
          </td>
          <td>
            {{item.group_name2}}
          </td>
          <td>
            {{item.association_post}}
          </td>
          <td>
            {{item.name}}
          </td>
          <td>
            {{item.email}}
          </td>
          <td>
            {{item.company_name}}
          </td>
          <td>
            {{item.post}}
          </td>
          <td>
            {{to_address(item.email, item.to)}}
          </td>

          <td class="center px-0" >
            <v-btn small class="mx-1 btn-font primary" v-on:click='show(item.sid, "edit")'
                :height="20" :width="50" :disabled="loading">
              <v-icon :small="true">mdi-border-color</v-icon>
              編集
            </v-btn>
            <v-btn small class="mx-1 ml-5 btn-font secondary" v-on:click='show(item.sid, "delete")'
                :height="20" :width="50" :disabled="loading">
              <v-icon :small="true">mdi-delete</v-icon>
              削除
            </v-btn>
          </td>
        </tr>
      </template>

    </v-data-table>

    <!-- modal -->
    <modal name="modal-dialog" :draggable="false" :clickToClose="false" :scrollable="true" 
                                height="auto" width="40%" id="vm--modal">
      <div >
        <!-- <div class="modal-header mt-5">
          <h2>会社編集</h2>
        </div> -->
        <div class="modal-body">
          <MailSendGroupEdit v-on:closeEvt="hide" :mode="mode" :sid="sid" />
        </div>
      </div>
    </modal>

  </v-container>

</template>

<script>
import MailSendGroup from '../model/mailSendGroup';
import MailSendGroupEdit from './MailSendGroupEdit';

import Message from '../Lib/Message';
import Debug from '../Lib/Debug';

export default {
  components: {
    MailSendGroupEdit,
  },

  data: () => ({
    loading: false,

    headers: [
      { text: '委員会', align: 'left', sortable: true, value: 'group_name1', width: 120 },
      { text: '部会', align: 'left', sortable: true, value: 'group_name2', width: 120 },
      { text: '協会役職', align: 'left', sortable: true, value: 'association_post', width: 120 },
      { text: '氏名', align: 'left', sortable: true, value: 'name', width: 120 },
      { text: 'メールアドレス', align: 'left', sortable: true, value: 'email', width: 300 },
      { text: '会社名', align: 'left', sortable: true, value: 'company_name', width: 300 },
      { text: '役職', align: 'left', sortable: true, value: 'post', width: 200 },
      { text: '宛先', align: 'left', sortable: true, value: 'to', width: 300 },
      { text: '', align: 'center', sortable: false, value: 'action', width: 200 },
    ],

    items: [],

    // 検索条件
    searchCondition: {
      group_name1: null,
      group_name2: null,
    },
    searchString: "",

    // edit用
    mode: '',
    sid: '',
  }),

  methods: {
    async loadList() {
      Debug.log('function[loadList]');

      this.loading = true
      try {
        const res = await MailSendGroup.getDataList(this.searchCondition);

        // メール送信グループ
        if (res && res.data) {
          Debug.log(res.data);

          this.items = res.data;
        }

      } catch (error) {
        Message.err(error, '情報を取得できませんでした。[メール送信グループ]');
      }
      this.loading = false
    },

    // モーダル表示用
    show (editid, mode) {
      Debug.log('function[show]');
      this.mode = mode;
      this.sid = editid; 

      Debug.log('edit mode : ' + mode);
      Debug.log('disp Id : ' + editid);

      this.$modal.show('modal-dialog');
    },
    hide : function () {
      Debug.log('function[hide]');
      this.$modal.hide('modal-dialog');

      // TABLEを読み直す
      this.loadList();
    },

    updateEvt () {
      Debug.log('function[updateEvt]');

      this.hide();
    },

    to_address(email, to) {
      if (!email) {
        return "";
      }
      return to + '<' + email + '>';
    },

  },

  computed: {
    filterItems() {
      if (this.items.length > 0) {
        return this.$refs.ref.$children[0].filteredItems;
      } else {
        return [];
      }
    },
  },

  created: function() {
    this.loadList()
  },

  mounted : function() {

  }

}

</script>


<style scoped>

.v-data-table table {
  width: initial !important;
}

.v-data-table table th {
  font-size: 12pt;
}

.v-data-table table td {
  font-size: 11pt;
}

/* modal dialog */
.modal-header, .modal-body {
  padding: 5px 25px;
}
.modal-header {
  border-bottom: 1px solid #ddd;
}

.vm--modal {
  min-width: 400px;
  max-width: 600px;
}

.btn-font {
  color: white;
}

.v-text-field {
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: 4px;
  margin-bottom: 4px;
}

th {
  white-space: pre-line;
}

.v-input--radio-group {
  margin: 0;
}

</style>
